// Build information, automatically generated by `ng-info`
const build = {
    version: "21.3.0-SNAPSHOT",
    timestamp: "Thu Nov 14 2024 18:09:58 GMT+0100 (Central European Standard Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "adbdb3",
        fullHash: "adbdb3461355b1b5395e9759dce0244677044abc"
    }
};

export default build;